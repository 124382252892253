import React, { useState } from 'react';
import {
  Stack,
  Text,
  HStack,
  VStack,
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Textarea,
  useDisclosure,
  Tooltip,
  chakra,
  useToast,
  ModalCloseButton,
} from '@chakra-ui/react';
import { GrSettingsOption } from 'react-icons/gr';
import { FiInfo } from 'react-icons/fi';

const CustomizePromptModal = ({ setPrompt }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputValue, setInputValue] = useState('');
  const [savedPrompt, setSavedPrompt] = useState('');

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleSave = () => {
    setSavedPrompt(inputValue);
    setPrompt(inputValue);
    toast({
      title: 'Custom instructions saved',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    onClose();
  };

  const handleDiscard = () => {
    setInputValue(savedPrompt);
  };

  return (
    <VStack>
      <Tooltip label="User settings">
        <HStack pb={2} spacing={2} cursor="pointer" onClick={onOpen}>
          <GrSettingsOption />
          <Text color="#1C1C1C" fontSize="14px" fontWeight="600">
            Settings
          </Text>
        </HStack>
      </Tooltip>

      {/* Modal */}
      <Modal isCentered size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="xl">Customize teris</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody py={4}>
            <Stack>
              <HStack>
                <Text fontSize="MD" fontWeight="semibold">
                  Custom Instructions
                </Text>
                <div className="tooltip">
                  <FiInfo cursor="pointer" color="grey" />
                  <span className="tooltip-text">
                    Provide a brief prompt to help teris responses to your
                    specific needs and preferences.
                  </span>
                </div>
              </HStack>
              <Text mb="3" fontSize="sm" w="90%">
                What would you like{' '}
                <chakra.span fontWeight="semibold">teris</chakra.span> to know
                about you to provide better responses?
              </Text>
            </Stack>
            <Textarea
              fontSize="sm"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Write your custom instructions here..."
              size="md"
              rows={10}
              resize="none"
              _focusWithin={{ boxShadow: 'none', borderColor: '#cbd5e0' }}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={handleDiscard}
              fontSize="sm"
              variant="outline"
              mr={3}
            >
              Discard
            </Button>
            <Button
              fontSize="sm"
              colorScheme="blue"
              onClick={handleSave}
              isDisabled={!inputValue}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default CustomizePromptModal;
