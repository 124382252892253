import React, { useState } from 'react';
import {
  Stack,
  Text,
  Img,
  HStack,
  VStack,
  Select,
  InputGroup,
  InputLeftElement,
  Input,
  Tooltip,
  IconButton,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import Logo from '../../assets/images/nowcfo.png';
import library from '../../assets/images/prompt.svg';
import { IoMdSearch } from 'react-icons/io';
import { GrSupport } from 'react-icons/gr';
import CustomizePromptModal from './CustomizePromptModal';
import NameAvatar from '../../utils/NameAvatar';
import signout from '../../assets/images/Logout.png';
import PromptModal from '../PromptLibraryModal'; // Import the modal component

const Sidebar = ({ setPrompt }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack
      width={{ base: '100%', md: '40%', lg: '30%', xl: '20%' }}
      display={{ base: 'none', md: 'inherit' }}
    >
      <Stack bg="#f7f9fb" justifyContent="space-between" p={5} minH="100vh">
        <Stack spacing={4}>
          <HStack alignItems="center" mb={4}>
            <Img cursor="pointer" src={Logo} w="170px" />
            {/* <Text color="yellow.400" ml={2} fontSize="24px" fontWeight="bold">
              Teris
            </Text> */}
          </HStack>

          <Stack spacing={3}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <IoMdSearch size={20} />
              </InputLeftElement>
              <Input
                bg="white"
                _focusVisible={{ outline: 'none' }}
                type="text"
                placeholder="Search"
                fontSize="sm"
              />
            </InputGroup>

            <Tooltip label="Select model">
              <Select
                _focusVisible={{ outline: 'none' }}
                cursor="pointer"
                bgColor="yellow.400"
              >
                <option value="chatgpt">ChatGPT</option>
              </Select>
            </Tooltip>
          </Stack>
          <Tooltip label="Prompt Library">
            <HStack
              w="fit-content"
              cursor={'pointer'}
              onClick={onOpen}
              spacing={2}
            >
              <Image boxSize={4} src={library} />
              <Text color="#1C1C1C" fontSize="14px" fontWeight="600">
                Prompt Library
              </Text>
            </HStack>
          </Tooltip>
        </Stack>

        <Stack direction="row" w="100%" justifyContent="space-between" mt={4}>
          <VStack spacing={2} w="100%" alignItems="flex-start">
            <Tooltip label="Support">
              <HStack>
                <GrSupport />
                <Text color="#1C1C1C" fontSize="14px" fontWeight="600">
                  Support
                </Text>
              </HStack>
            </Tooltip>
            <CustomizePromptModal setPrompt={setPrompt} />
            <HStack
              w="100%"
              justifyContent="space-between"
              spacing={4}
              alignItems="center"
            >
              <HStack>
                <NameAvatar name={'User'} />
                <Text color="#1B2559" fontSize="14px" fontWeight="700">
                  Olivia
                </Text>
              </HStack>
              <Tooltip label="Logout">
                <Img
                  cursor="pointer"
                  onClick={() => {
                    localStorage.clear();
                  }}
                  src={signout}
                />
              </Tooltip>
            </HStack>
          </VStack>
        </Stack>
      </Stack>

      {/* Prompt Modal */}
      <PromptModal isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
};

export default Sidebar;
