import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import Papa from 'papaparse';

const CsvTable = ({ csvUrl }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch CSV file
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await fetch(csvUrl);
        const reader = response.body.getReader();
        const result = await reader.read(); // raw array
        const decoder = new TextDecoder('utf-8');
        const csvData = decoder.decode(result.value); // the csv text
        // Parse CSV
        Papa.parse(csvData, {
          header: true, // If your CSV has headers
          skipEmptyLines: true,
          complete: results => {
            setData(results.data); // Set the parsed data
            setLoading(false); // Stop loading
          },
        });
      } catch (error) {
        setLoading(false); // Stop loading on error
        console.error('Error fetching CSV data:', error);
      }
    };

    fetchData();
  }, [csvUrl]);

  return (
    <TableContainer
      border={'1px solid #f5f5f5'}
      rounded={'lg'}
      minH={'300px'} // Minimum height to avoid layout shifts
      position="relative"
    >
      {/* Show the loader in the center if loading */}
      {loading && (
        <Flex
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          align="center"
          justify="center"
        >
          <Spinner size="xl" />
        </Flex>
      )}

      {/* Show the table only when data is loaded */}
      {!loading && (
        <Table fontSize={'sm'} variant="striped">
          <Thead bg="#342e2e">
            <Tr>
              {/* Display the column headers */}
              {data &&
                data.length > 0 &&
                Object.keys(data[0]).map((key, index) => (
                  <Th py={4} color="white" key={index}>
                    {key}
                  </Th>
                ))}
            </Tr>
          </Thead>
          <Tbody>
            {/* Display the rows */}
            {data &&
              data.slice(0, 50).map((row, rowIndex) => (
                <Tr key={rowIndex}>
                  {Object.values(row).map((value, colIndex) => (
                    <Td key={colIndex}>{value}</Td>
                  ))}
                </Tr>
              ))}

            {/* If there are more than 50 rows, add an extra row with "---" */}
            {data && data.length > 50 && (
              <Tr>
                {data &&
                  Object.keys(data[0]).map((key, index) => (
                    <Td key={index}>---</Td>
                  ))}
              </Tr>
            )}
          </Tbody>
        </Table>
      )}
    </TableContainer>
  );
};

export default CsvTable;
