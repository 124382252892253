import React, { useState, useEffect, useRef } from 'react';
import {
  Stack,
  Text,
  Img,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  Flex,
  Tooltip,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  IconButton,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Textarea,
} from '@chakra-ui/react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import Sidebar from '../sidebar/Sidebar';
import Drawer from '../sidebar/Drawer';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { BsPlusCircleFill } from 'react-icons/bs';
import { CgAttachment } from 'react-icons/cg';
import { FiFilePlus, FiFileText } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { FaArrowAltCircleDown, FaRegEye } from 'react-icons/fa';

import loader from '../../assets/images/loading.gif';
import logo from '../../assets/images/cfo-logo.png';
import sendIcon from '../../assets/images/send.png';

import './chat.css';
import CsvTable from '../CSVTable';
import { baseuRL } from '../../environment';

// Custom Components for Markdown
const CustomH1 = ({ children }) => <h1 className="markdown-h1">{children}</h1>;
const CustomH2 = ({ children }) => <h2 className="markdown-h2">{children}</h2>;
const CustomH3 = ({ children }) => <h3 className="markdown-h3">{children}</h3>;
const CustomH4 = ({ children }) => <h4 className="markdown-h4">{children}</h4>;
const CustomH5 = ({ children }) => <h5 className="markdown-h5">{children}</h5>;
const CustomH6 = ({ children }) => <h6 className="markdown-h6">{children}</h6>;
const CustomUL = ({ children }) => <ul className="markdown-ul">{children}</ul>;
const CustomOL = ({ children }) => <ol className="markdown-ol">{children}</ol>;
const CustomLI = ({ children }) => <li className="markdown-li">{children}</li>;

const Webchat = () => {
  const [loading, setLoading] = useState(false);
  const [isFileProcessed, setIsFileProcessed] = useState(false);
  const [query, setQuery] = useState('');
  const [prompt, setPrompt] = useState('');
  const [faqs, setFaqs] = useState([]);
  const [s3Fileurl, setS3FileUrl] = useState('');
  const faqRefs = useRef([]);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false); // Tooltip control
  const [fileContent, setFileContent] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const textareaRef = useRef(null); // Ref for Textarea

  useEffect(() => {
    if (faqs.length > 0) {
      const lastFaqIndex = faqs.length - 1;
      faqRefs.current[lastFaqIndex]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [faqs]);

  const handleChatSubmit = async ques => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height to original
    }
    const currentques = ques || query;
    setLoading(true);
    setFaqs(prevFaqs => [
      ...prevFaqs,
      { query: '', answer: 'Awaiting answer...' },
    ]);
    setQuery('');

    try {
      const maskResponse = await axios.post(`${baseuRL}/mask-text`, {
        text: currentques,
      });
      const maskedText = maskResponse.data.masked_text;
      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1 ? { ...faq, query: maskedText } : faq
        )
      );

      const flowResponse = await axios.post(`${baseuRL}/run-flow`, {
        query: maskedText,
        persona: prompt,
        s3_path: s3Fileurl || '',
      });
      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: flowResponse.data.answer }
            : faq
        )
      );
    } catch (error) {
      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: 'Error retrieving answer' }
            : faq
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && query.trim() && !loading) {
      handleChatSubmit();
    }
  };

  // New function to handle file uploads and processing
  const handleFileChange = async e => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const fileType = uploadedFile.type;
      // Allow CSV and plain text files
      if (
        fileType.includes('csv') ||
        fileType.includes('text/csv') ||
        fileType.includes('application/csv') ||
        fileType === 'text/plain' // Added support for text files
      ) {
        setFile({
          name: uploadedFile.name,
          url: URL.createObjectURL(uploadedFile),
          type: fileType,
        });

        // Prepare FormData to send the file
        const formData = new FormData();
        formData.append('file', uploadedFile);

        try {
          const response = await axios.post(
            `${baseuRL}/upload-file`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          const s3Url = response.data.s3_url;
          setS3FileUrl(s3Url);
          setIsFileProcessed(true);
          if (fileType === 'text/plain') {
            // Fetch the text content for text files
            const textResponse = await axios.get(s3Url);
            setFileContent(textResponse.data);
          }
        } catch (error) {
          toast({
            title: 'Error processing file.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        }
      } else {
        toast({
          title: 'Please upload a valid CSV or text file.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        setFile(null);
      }
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const removeFile = () => {
    setS3FileUrl(null);
    setIsFileProcessed(false);
    setFile(null);
    fileInputRef.current.value = null;
    setFileContent(''); // Clear the text content
  };

  const handlePreviewClick = () => {
    setTooltipOpen(false);
    if (file) {
      onOpen(); // Open modal to view file
    } else {
      alert('No file available for preview.');
    }
  };

  const renderFileIcon = fileType => {
    if (fileType?.includes('csv')) {
      return <FiFileText fontSize={24} color="white" />;
    }
    return <FiFileText fontSize={24} color="white" />;
  };

  const handleChat = () => {
    setFaqs([]); // Clears the FAQs (chat history)
    setFile(null); // Clears the selected file
    setS3FileUrl(''); // Clears the file URL if any
    setQuery(''); // Clears the input field
    // No need to set fileInputRef.current to null, just clear its value
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clears the file input without losing its reference
    }
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height to original
    }
  };

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      height="100vh"
      width="100%"
      spacing={0}
    >
      <Sidebar setPrompt={setPrompt} />
      <Drawer />

      <Flex direction="column" flex="1">
        {/* Header Section - Fixed */}
        <Stack
          position="sticky"
          top={0}
          bg="white"
          zIndex={1}
          w="100%"
          boxShadow="lg"
          py={4}
          px={4}
        >
          <HStack
            alignSelf={{ base: 'center', md: 'start' }}
            spacing={6}
            w="100%"
          >
            <VStack w="100%" align="start" spacing={0}>
              <HStack w="100%" justifyContent="space-between">
                <Stack>
                  <Heading
                    color={'#1B2559'}
                    fontWeight={'600'}
                    fontSize={{ base: '20px', lg: '25px' }}
                    fontFamily={'Poppins !important'}
                  >
                    Welcome back, Olivia
                  </Heading>
                  <Text
                    fontSize={{ base: '10px', md: '14px' }}
                    color="gray.600"
                    fontFamily={'Poppins !important'}
                  >
                    Track, manage and forecast your customers and orders
                  </Text>
                </Stack>
                <Tooltip label="Start new chat">
                  <HStack
                    onClick={handleChat}
                    cursor={'pointer'}
                    bgColor={'rgba(247, 249, 251, 1)'}
                    borderRadius={'10px'}
                    px={4}
                    py={2}
                    w={'fit-content'}
                    alignSelf={'center'}
                  >
                    <BsPlusCircleFill size={20} />
                    <Text color={'#1C1C1C'} fontSize={'sm'} fontWeight={'400'}>
                      New Chat
                    </Text>
                  </HStack>
                </Tooltip>
              </HStack>
            </VStack>
          </HStack>
        </Stack>
        {/* Main Chat Content */}
        <Stack flex="1" overflowY="auto" pt={6} pb={4}>
          {faqs.map((faq, index) => (
            <Stack
              key={index}
              mb={4}
              px={4}
              w="100%"
              alignItems="flex-start"
              ref={el => (faqRefs.current[index] = el)}
            >
              <HStack w="100%" p={2} justifyContent="flex-end">
                {faq.query !== '' && (
                  <VStack
                    align="end"
                    spacing={0}
                    minW="50px"
                    w="fit-content"
                    textAlign="center"
                  >
                    <Stack bg="ButtonShadow" borderRadius="10px" p={3} w="100%">
                      <Text
                        whiteSpace="break-spaces"
                        textAlign={'start'}
                        color="#1C1C1C"
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight={1.7}
                      >
                        {faq.query}
                      </Text>
                    </Stack>
                  </VStack>
                )}
              </HStack>
              <VStack
                position="relative"
                w="95%"
                p={2}
                alignItems="flex-start"
                spacing={4}
              >
                {faq.answer === 'Awaiting answer...' ? (
                  <Img alignSelf="center" w={14} src={loader} />
                ) : (
                  <Flex alignItems="flex-start" gap={3}>
                    <Img src={logo} w={6} h={6} />
                    <Text
                      color="#1B2559"
                      fontSize="14px"
                      fontWeight="400"
                      w="90%"
                    >
                      <ReactMarkdown
                        children={faq.answer}
                        components={{
                          h1: CustomH1,
                          h2: CustomH2,
                          h3: CustomH3,
                          h4: CustomH4,
                          h5: CustomH5,
                          h6: CustomH6,
                          ul: CustomUL,
                          ol: CustomOL,
                          li: CustomLI,
                        }}
                      />
                    </Text>
                  </Flex>
                )}
              </VStack>
            </Stack>
          ))}
        </Stack>

        {/* Input field fixed at the bottom */}
        <Flex py={4} px={4} w="100%" align="center">
          <Stack bg="#f4f4f4" borderRadius="2xl" p={2} w={'100%'}>
            {/* File Preview inside the Input */}
            {file && (
              <Flex
                position={'relative'}
                bg="white"
                width={'fit-content'}
                p={4}
                alignItems="center"
                gap={3}
              >
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  rounded={'md'}
                  p={2}
                  bg={file.type === 'text/plain' ? '#FF5588' : 'green'} // Dynamic background color
                >
                  {renderFileIcon(file.type)}
                </Flex>
                <Stack spacing={0}>
                  <Box
                    mr={4}
                    fontWeight={'semibold'}
                    fontSize="sm"
                    isTruncated
                    maxW="200px"
                    w={'200px'}
                    mb={0}
                    title={file.name}
                  >
                    {file.name}
                  </Box>
                  <Text fontSize={'xs'} color={'grey'}>
                    {isFileProcessed
                      ? 'Processing completed'
                      : 'Pre-processing document...'}
                  </Text>
                </Stack>
                {isFileProcessed && (
                  <Tooltip
                    isOpen={tooltipOpen}
                    onClose={() => setTooltipOpen(false)}
                    label="View file"
                    closeOnClick={true}
                  >
                    <IconButton
                      _hover={{ bg: 'none' }}
                      style={{ position: 'absolute', top: 5, right: 6 }}
                      aria-label="View file"
                      size="xs"
                      icon={<FaRegEye fontSize={15} />}
                      onClick={handlePreviewClick}
                      onMouseEnter={() => setTooltipOpen(true)} // Show tooltip on hover
                      onMouseLeave={() => setTooltipOpen(false)} // Hide tooltip on mouse leave
                      bg="none"
                    />
                  </Tooltip>
                )}

                <Tooltip label="Remove file">
                  <IconButton
                    position={'absolute'}
                    top={-3}
                    right={-3}
                    aria-label="Remove file"
                    size="xs"
                    icon={<AiOutlineClose />}
                    onClick={removeFile}
                    ml={2}
                    rounded={'full'}
                    bg="white"
                    border={'1px solid grey'}
                  />
                </Tooltip>
              </Flex>
            )}

            <Box position="relative" w="100%">
              <Textarea
                ref={textareaRef} // Attach ref to Textarea
                border="none"
                outline="none"
                fontSize="sm"
                value={query}
                onChange={e => setQuery(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevents new line
                    if (query.trim() && !loading) {
                      handleChatSubmit(); // Calls the submit function
                    }
                  }
                }}
                placeholder="Ask a question"
                _placeholder={{ fontSize: 14 }}
                _focusVisible={{ outline: 'none' }}
                resize="none" // Prevent manual resize
                maxHeight="200px" // Max height (approximately 5-6 rows)
                overflowY="auto" // Vertical scrollbar when content exceeds max height
                style={{
                  whiteSpace: 'pre-wrap', // Ensures text wraps correctly
                  wordWrap: 'break-word', // Break long words if necessary
                }}
                pl="40px" // Padding to leave space for left icon
                pr="40px" // Padding to leave space for right icon
                lineHeight={1.5}
                rows={1} // Initial rows count, can dynamically grow
                onInput={e => {
                  e.target.style.height = 'auto'; // Reset the height
                  e.target.style.height = `${Math.min(
                    e.target.scrollHeight,
                    200
                  )}px`; // Set the height dynamically with max limit
                }}
              />

              {/* Left Icon */}
              <Box
                position="absolute"
                left="10px"
                bottom="-12px"
                transform="translateY(-50%)"
              >
                <Menu>
                  <MenuButton
                    aria-label="Upload file"
                    title="Upload file"
                    cursor={file ? 'not-allowed' : 'pointer'}
                    disabled={file}
                  >
                    <CgAttachment
                      size="20px"
                      color="#418DB8"
                      onClick={handleIconClick}
                    />
                  </MenuButton>

                  <MenuList boxShadow="lg">
                    <MenuItem
                      onClick={handleIconClick}
                      bg="transparent"
                      _hover={{ bg: 'none' }}
                    >
                      <FiFilePlus />
                      <span style={{ marginLeft: 8, fontSize: 14 }}>
                        Upload from computer
                      </span>
                    </MenuItem>
                  </MenuList>
                </Menu>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Box>

              {/* Right Icon */}
              <Box
                position="absolute"
                right="10px"
                bottom="-6px"
                transform="translateY(-50%)"
              >
                <Tooltip label="Send query">
                  <Img
                    cursor={query.trim() ? 'pointer' : 'not-allowed'}
                    onClick={() => {
                      if (query.trim()) {
                        handleChatSubmit();
                      }
                    }}
                    src={sendIcon}
                    w={6}
                    h={6}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Stack>
        </Flex>
      </Flex>

      {/* Modal for File Preview */}
      <Modal
        isCentered
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {file?.type === 'text/plain'
              ? 'Text File Preview'
              : 'CSV File Preview'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {file?.type === 'text/csv' || file?.type === 'application/csv' ? (
              <CsvTable csvUrl={s3Fileurl} />
            ) : (
              <Box whiteSpace="pre-wrap">
                <Text>{fileContent}</Text>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default Webchat;
