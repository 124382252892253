import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
  IconButton,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { baseuRL } from '../environment';

const PromptModal = ({ isOpen, onClose }) => {
  const [prompts, setPrompts] = useState([]);
  const toast = useToast(); // Chakra UI toast for notifications

  // Fetch prompts from backend API
  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const { data } = await axios.get(`${baseuRL}/prompt_library`);
        setPrompts(data);
      } catch (error) {
        console.log('🚀 ~ fetchPrompts ~ error:', error);
      }
    };
    fetchPrompts();
  }, []);

  // Function to copy the prompt to clipboard
  const handleCopy = promptText => {
    navigator.clipboard.writeText(promptText);
    toast({
      title: 'Copied to clipboard!',
      description: 'The prompt has been copied successfully.',
      status: 'success',
      duration: 1000,
      isClosable: true,
      position: 'top',
    });
  };

  return (
    <Modal isCentered size={'3xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Prompt Library</ModalHeader>
        <ModalCloseButton />
        <ModalBody py={6}>
          <Stack direction={'row'} spacing={6}>
            {prompts?.map((prompt, index) => (
              <Stack
                borderWidth="1px"
                borderRadius="lg"
                p={4}
                position="relative"
                minW={'300px'}
              >
                <Text fontSize="lg" fontWeight="bold">
                  {prompt.name}
                </Text>
                <Text fontSize={'sm'}>
                  {prompt.Description || 'Loading...'}
                </Text>
                <Tooltip label="Copy Prompt">
                  <IconButton
                    icon={<CopyIcon />}
                    aria-label="Copy Sales Prompt"
                    position="absolute"
                    top="4px"
                    right="4px"
                    size="sm"
                    onClick={() => handleCopy(prompt.Prompt)}
                  />
                </Tooltip>
              </Stack>
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PromptModal;
