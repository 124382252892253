import React, { useState } from 'react';
import { Stack, Text, Button, Img, HStack, Divider } from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { BsList, BsPlusCircleFill } from 'react-icons/bs';
import Logo from '../../assets/images/cfo-logo.png';
import signout from '../../assets/images/Logout.png';
import { useNavigate } from 'react-router-dom';
import NameAvatar from '../../utils/NameAvatar';

const DrawerMob = ({ openChat }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const navigator = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  return (
    <Stack
      direction={'row'}
      width={'100%'}
      display={{ base: 'inherit', md: 'none' }}
      justifyContent={'space-between'}
      p={3}
    >
      <Stack>
        <Button
          w={'fit-content'}
          ref={btnRef}
          variant="none"
          colorScheme="teal"
          onClick={onOpen}
        >
          <BsList fontSize={'30px'} />
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <Stack
                bgColor={'rgba(255, 255, 255, 1)'}
                boxShadow={'2xl'}
                borderRadius={'20px'}
                marginBlock={8}
                py={4}
                h={'fit-content'}
              >
                <Stack>
                  <Stack alignItems={'center'} mb={3}>
                    <Img src={Logo} w={'52px'} />
                  </Stack>
                  <Divider mb={3} />
                  <Stack>
                    <Stack gap={4}>
                      <Stack onClick={openChat}>
                        <HStack
                          cursor={'pointer'}
                          bgColor={'rgba(247, 249, 251, 1)'}
                          borderRadius={'10px'}
                          px={4}
                          py={2}
                          mx={3}
                        >
                          <BsPlusCircleFill size={20} />
                          <Text
                            color={'#1C1C1C'}
                            fontSize={'sm'}
                            fontWeight={'400'}
                          >
                            New Chat
                          </Text>
                        </HStack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  bgColor={'rgba(255, 255, 255, 1)'}
                  boxShadow={'2xl'}
                  borderRadius={'20px'}
                  direction={'row'}
                  mx={3}
                  justifyContent={'space-between'}
                  mt={4}
                >
                  <HStack marginLeft={4}>
                    <NameAvatar name={'User'} />
                    <Text
                      color={'#1B2559'}
                      fontSize={'14px'}
                      fontWeight={'700'}
                    >
                      {userInfo?.name}
                    </Text>
                  </HStack>
                  <Stack>
                    <Button
                      onClick={() => {
                        localStorage.clear();
                        navigator('/');
                      }}
                      colorScheme="teal"
                      variant="none"
                    >
                      <Img src={signout} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Stack>
    </Stack>
  );
};

export default DrawerMob;
